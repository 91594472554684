import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Masonry from "react-masonry-component"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'

let PostLink = styled(Link)`
  color: ${props => props.theme.gray900};
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.primary};
  }
`

let PostTitle = styled.h2`
  font-size: 24px;
  // margin-top: 30px;
  // margin-bottom: 10px;
`

let PostSubTitle = styled.h4`
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 10px;
  @include serif-font;
  // font-family: 'Cormorant', 'Times New Roman', serif;
  color: ${props => props.theme.gray600};
`

let PostMeta = styled.div`
  font-size: 18px;
  font-style: italic;
  margin-top: 0;
  margin: 0px 0px;
  color: ${props => props.theme.gray600};
`

let PostCard = styled.div`
  // margin-bottom: 30px;

  @media screen and (min-width: 0px) {
    box-sizing: border-box;
    width: 100%;
    float: left;
    padding-left: 15px;
  }

  @media screen and (min-width: 1400px) {
    width: 25%;
  }

  @media screen and (min-width: 900px) {
    width: 50%;
  }

  @media screen and (min-width: 1100px) {
    width: 33%;
    padding-left: 40px;
  }
`

// We have created a function that returns a component based on the parameter passed to the function
export default (articles) => {   
  return () => {   
      return (
        <Masonry>
          { 
            articles.map((article, index) => { 
              const slug = article.fields.slug
              const {timeToRead} = article
              const {title, subtitle, type, duration, hero, date} = article.frontmatter
              const image =  hero ? hero.childImageSharp.fluid : null

              const time = duration || timeToRead

              return (                
                    <PostCard key={slug}>
                      <figure className="rounded p-3 bg-white shadow-sm position-relative">
                        {hero &&
                          <Img fluid={image} />
                        }
                        <figcaption className="p-4 card-img-bottom">
                          <PostLink to={slug} className="stretched-link">
                            <PostTitle>
                                {title}
                            </PostTitle>
                            <PostSubTitle>
                              {subtitle}
                            </PostSubTitle>
                          </PostLink>
                          <PostMeta>
                            {type &&
                              <>
                                <FontAwesomeIcon icon={faVideo} />                              
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </>
                            }
                            {date}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{time}&nbsp;mins
                            </PostMeta>
                        </figcaption>
                      </figure>
                    </PostCard>
              )
            })
          }
        </Masonry>
      )
    }
  }